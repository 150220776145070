import React from 'react';
import _ from 'lodash';

export function UserAvatar({ user, id: userId, className, ...other }) {
  if(_.isString(user)) {
    user = {username: user, id: userId || user}
  }

  let { username, id } = user || { username: '?', id: userId || 'undefined' };

  let avatarColorIndex = 0;
  const AVAILABLE_COLORS = 48;
  try {
    avatarColorIndex = parseInt((id || '000').slice(-2), 16) % AVAILABLE_COLORS;
  } catch (err) {
    console.log('Broken avatar-user');
  }

  let initials = (username || '??').replace(/\s/g, '').slice(0, 2).toUpperCase();

  if (id.startsWith('casual-')) {
    initials = '👁‍🗨';
  }

  if(id === 'sheetScript') {
    initials = '🤖'
    avatarColorIndex = "other";
  }

  return <a href={`${window.config.opinautosUrl}/miembros/perfil/${id}`} target={'_blank'}
            className={'avt' + avatarColorIndex + ' avatar-small gen-avatar align-middle '+(className || '')} title={username} {...other}>
    <span className={'initials'}>{initials}</span>
  </a>;
}
